<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('onlyCloseModal')"
    title="Update Extension"
    no-close-on-backdrop
  >
    <div class="d-block">
      <validation-observer ref="form">
        <b-container>
          <b-row>
            <b-col cols="10">
              <b-form-group label="Name:">
                <b-form-input
                  v-model="extension.name"
                  type="text"
                  required
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Extension:">
                <b-form-input
                  v-model="extension.extension"
                  disabled
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6" v-if="typeModal == 'module'">
              <validation-provider
                v-slot="{ errors }"
                name="module_id"
                rules="required"
              >
                <b-form-group label="Module:">
                  <v-select
                    v-model="extension.module_id"
                    label="name"
                    :options="modules"
                    :reduce="(value) => value.id"
                    :class="errors[0] ? 'border-danger' : ''"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="typeModal == 'assigned_to'">
              <b-form-group label="Assign to:">
                <vue-autosuggest
                  ref="autocomplete"
                  id="users"
                  v-model="extension.assigned_to"
                  :suggestions="filteredOptions"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: ['form-control'],
                    placeholder: 'Search by Name',
                  }"
                  @input="onInputChange"
                  @selected="selectHandler"
                >
                  <template slot="before-input">
                    <div
                      class="lds-dual-ring"
                      v-show="loadingAutosuggest"
                    ></div>
                  </template>
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">{{
                      suggestion.item.first_name +
                      " " +
                      suggestion.item.last_name
                    }}</span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Number:">
                <b-form-input
                  v-model="extension.number"
                  type="text"
                  disabled
                  v-mask="'(###)-###-####'"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </validation-observer>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="$emit('onlyCloseModal')"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="saveExtension"> Save </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import RCService from "@/views/management/views/settings/components/ring-central/services/RCService.js";
import RingcentralService from "@/service/commons/ringcentral.service.js";
import UsersService from "@/service/commons/users.service.js";
import { VueAutosuggest } from "vue-autosuggest";
export default {
  props: {
    objExtension: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: null,
          module_id: null,
          name: null,
          number: null,
          extension: null,
          password: null,
        };
      },
    },

    typeModal: {
      type: String,
    },
  },

  components: {
    VueAutosuggest,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  data() {
    return {
      modalShow: true,
      extension: { ...this.objExtension },
      modules: [],

      userSelected: null,
      filteredOptions: [],
      loadingAutosuggest: false,
    };
  },

  methods: {
    onlyCloseModal: function () {
      this.$emit("closeModal");
    },

    async getModules() {
      const data = await RCService.getModules();
      this.modules = data;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.first_name + " " + suggestion.item.last_name;
    },

    async onInputChange(text) {
      this.loadingAutosuggest = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.loadingAutosuggest = false;
        return;
      }

      try {
        const { data } = await UsersService.getAll({
          search_by: text,
        });

        this.filteredOptions = [
          {
            data: data,
          },
        ];
        this.loadingAutosuggest = false;
      } catch (error) {
        console.log(error);
      }
    },

    selectHandler(user) {
      if (user === undefined || user === null) {
        return;
      }

      this.userSelected = user.item.id;
    },

    async saveExtension() {
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      try {
        this.addPreloader();

        this.extension.created_by = this.currentUser.user_id;
        const params = {
          module_id: this.extension.module_id,
          assigned_to: this.userSelected,
        };

        const data = await RingcentralService.updateCredential(
          this.extension.id,
          params
        );
        this.showGenericSuccessSwal({});
        this.$emit("update");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },

  mounted() {
    this.getModules();
  },
};
</script>

<style>
</style>