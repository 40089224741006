import { amgApi } from "@/service/axios";

class SettingsRCService {
    async getModules() {
        try {
            const data = await amgApi.get('/commons/employee-claims/get-modules');
            return data.data;
        } catch (error) {
            console.log("Error getModules");
        }
    }

    async getCredentials(payload) {
        try {
            const data = await amgApi.post('/ring-central/credentials/paginate', payload);
            return data;
        } catch (error) {
            console.log("Error getCredentials");
            throw error;
        }
    }


    async syncCredentials(payload) {
        try {
            const data = await amgApi.post('/ring-central/credentials/sync', payload);
            return data;
        } catch (error) {
            console.log("Error syncCredentials");
            throw error;
        }
    }

    async migrateManualCallRecording(payload) {
        try {
            const data = await amgApi.post('/ring-central/migrate-manual-calls-recording', payload);
            return data;
        } catch (error) {
            console.log("Error migrateManualCallRecording");
            throw error;
        }
    }

}

export default new SettingsRCService();
