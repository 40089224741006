<template>
  <div>
    <b-button @click="modalShow = true" variant="outline-warning">
      Migrate
    </b-button>
    <b-modal
      v-model="modalShow"
      @hidden="modalShow = false"
      no-close-on-backdrop
      title="Migrate Calls"
      hide-footer
    >
      <validation-observer ref="form">
        <validation-provider v-slot="{ errors }" name="date" rules="required">
          <b-form-group label="Date">
            <kendo-datepicker
              id="date"
              v-model="date"
              v-mask="'##/##/####'"
              placeholder="MM/DD/YYYY"
              :format="'MM/dd/yyyy'"
              :state="errors[0] ? false : null"
              :class="errors[0] ? 'border-danger' : ''"
              :min="minDate"
              class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
            />
            <small class="text-danger">
              {{ errors[0] }}
            </small>
          </b-form-group>
          <small class="text-secondary mt-1">
            This operation will migrate the Ring Central calls to the company
            server
          </small>
        </validation-provider>
      </validation-observer>
      <div class="d-flex justify-content-end">
        <b-button variant="secondary" @click="modalShow = false">
          Cancel
        </b-button>
        <b-button variant="primary" class="ml-1" @click="migrateManual">
          Migrate
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RingCentralService from "@/views/management/views/settings/components/ring-central/services/RCService.js";
import moment from "moment";
export default {
  computed: {
    minDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date;
    },
  },
  data() {
    return {
      modalShow: false,
      date: null,
    };
  },

  methods: {
    async migrateManual() {
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;

      const result = await this.showQuestionSwal(
        "Do you want to perform this action?",
        "It could affect the rest of the user."
      );
      if (result.isConfirmed) {
        const tomor = moment(this.date, "MM/DD/YYYY").add(1, "days");
        const realDate = tomor.format("YYYY-MM-DD");

        const params = {
          date_to: realDate,
        };
        const response = await RingCentralService.migrateManualCallRecording(
          params
        );
      }
    },
  },
};
</script>

<style>
</style>