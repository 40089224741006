<template>
  <div>
    <b-card no-body class="mb-0">
      <filter-slot
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="[]"
        @reload="$refs['RCTable'].refresh()"
      >
        <template #buttons>
          <div class="d-flex justify-content-between">
            <b-button
              variant="outline-primary"
              class="mx-2"
              @click="synCredentials()"
            >
              Sync RC
            </b-button>
            <!-- <manual-migrate /> -->
          </div>
        </template>
        <template #table>
          <b-table
            id="RCTable"
            slot="table"
            ref="RCTable"
            :items="myProvider"
            :fields="fields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="[]"
            table-class="text-nowrap"
            responsive="sm"
            no-border-collapse
            sticky-header="70vh"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(module)="data">
              <div class="text-center">
                {{ data.item.module }}
                <feather-icon
                  v-if="!data.item.module && !data.item.assigned_to"
                  @click="openModal(data.item, 'module')"
                  icon="PlusIcon"
                  class="text-success cursor-pointer"
                  v-b-tooltip.left.hover
                  title="Select Module"
                />

                <feather-icon
                  v-if="data.item.module"
                  @click="deleteModuleAssignedTo(data.item.id)"
                  icon="XSquareIcon"
                  class="text-danger cursor-pointer"
                />
              </div>
            </template>

            <template #cell(assigned_to)="data">
              <div class="text-center">
                {{ data.item.assigned_to }}

                <feather-icon
                  v-if="data.item.assigned_to"
                  @click="deleteUserAssignedTo(data.item.id)"
                  icon="XSquareIcon"
                  class="text-danger cursor-pointer"
                />
                <feather-icon
                  v-if="!data.item.assigned_to && !data.item.module"
                  @click="openModal(data.item, 'assigned_to')"
                  icon="PlusIcon"
                  class="text-success cursor-pointer"
                  v-b-tooltip.left.hover
                  title="Select User"
                />
              </div>
            </template>
            <template #cell(created_at)="data">
              <div>
                {{ data.item.created_at | myGlobal }}
              </div>
            </template>
            <template #cell(updated_at)="data">
              <div>
                {{ data.item.updated_at | myGlobal }}
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <modal-ring-central
      v-if="showModal"
      :obj-extension="extension"
      :type-modal="typeModal"
      @onlyCloseModal="showModal = false"
      @update="reloadAll"
    ></modal-ring-central>
  </div>
</template>

<script>
import Fields from "@/views/management/views/settings/components/ring-central/data/Fields.data.js";
import { mapGetters } from "vuex";
import ModalRingCentral from "@/views/management/views/settings/components/ring-central/components/ModalRingCentral.vue";
import RCService from "@/views/management/views/settings/components/ring-central/services/RCService.js";
import ManualMigrate from "@/views/management/views/settings/components/ring-central/components/ManualCallRecording.vue";
import RingcentralService from "@/service/commons/ringcentral.service.js";
export default {
  components: { ModalRingCentral, ManualMigrate },

  data() {
    return {
      totalRows: 0,
      fields: Fields,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search By Phone Number, Extension, Name, Module...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      showModal: false,
      extension: null,
      typeModal: "module",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async myProvider(ctx) {
      try {
        const params = {
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort_desc: ctx.sortDesc ? "desc" : "asc",
        };
        const { data } = await RCService.getCredentials(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        return data.data || [];
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async synCredentials() {
      const result = await this.showQuestionSwal(
        "Do you want to perform this action?",
        "It update all extensions."
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {};
          const { data } = await RCService.syncCredentials(params);
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.$refs["RCTable"].refresh();
          this.removePreloader();
        }
      }
    },

    async deleteUserAssignedTo(id) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          await RingcentralService.updateCredential(id, {
            assigned_to: "delete",
          });
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.$refs["RCTable"].refresh();
          this.removePreloader();
        }
      }
    },

    async deleteModuleAssignedTo(id) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          await RingcentralService.updateCredential(id, {
            module_id: "delete",
          });
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.$refs["RCTable"].refresh();
          this.removePreloader();
        }
      }
    },

    openModal(extension, type) {
      this.extension = extension;
      this.typeModal = type;
      this.showModal = true;
    },
    reloadAll() {
      this.$refs["RCTable"].refresh();
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
</style>