var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Update Extension","no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('onlyCloseModal')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$emit('onlyCloseModal')}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveExtension}},[_vm._v(" Save ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{staticClass:"d-block"},[_c('validation-observer',{ref:"form"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label":"Name:"}},[_c('b-form-input',{attrs:{"type":"text","required":"","disabled":""},model:{value:(_vm.extension.name),callback:function ($$v) {_vm.$set(_vm.extension, "name", $$v)},expression:"extension.name"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label":"Extension:"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.extension.extension),callback:function ($$v) {_vm.$set(_vm.extension, "extension", $$v)},expression:"extension.extension"}})],1)],1),(_vm.typeModal == 'module')?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"module_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Module:"}},[_c('v-select',{class:errors[0] ? 'border-danger' : '',attrs:{"label":"name","options":_vm.modules,"reduce":function (value) { return value.id; },"clearable":false},model:{value:(_vm.extension.module_id),callback:function ($$v) {_vm.$set(_vm.extension, "module_id", $$v)},expression:"extension.module_id"}})],1)]}}],null,false,3865202666)})],1):_vm._e(),(_vm.typeModal == 'assigned_to')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Assign to:"}},[_c('vue-autosuggest',{ref:"autocomplete",attrs:{"id":"users","suggestions":_vm.filteredOptions,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                  id: 'autosuggest__input',
                  class: ['form-control'],
                  placeholder: 'Search by Name',
                }},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.first_name + " " + suggestion.item.last_name))])]}}],null,false,3420544669),model:{value:(_vm.extension.assigned_to),callback:function ($$v) {_vm.$set(_vm.extension, "assigned_to", $$v)},expression:"extension.assigned_to"}},[_c('template',{slot:"before-input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingAutosuggest),expression:"loadingAutosuggest"}],staticClass:"lds-dual-ring"})])],2)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Number:"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###)-###-####'),expression:"'(###)-###-####'"}],attrs:{"type":"text","disabled":""},model:{value:(_vm.extension.number),callback:function ($$v) {_vm.$set(_vm.extension, "number", $$v)},expression:"extension.number"}})],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }