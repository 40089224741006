import { amgApi } from "@/service/axios";

class UsersService {

    async getAll(payload) {
        try {
            const data = await amgApi.post(`/new-commons/users/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on UsersService.getAll:", error);
            throw error;
        }
    }
}

export default new UsersService();
