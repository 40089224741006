export default [
  {
    key: "name",
    label: "Name",
    thClass: "text-left",
    tdClass: "text-left",
    sortable: false,
  },
  {
    key: "module",
    label: "Module",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
  },
  {
    key: "assigned_to",
    label: "Assigned To",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
  },
  {
    key: "number",
    label: "Number",
    sortable: false,
  },
  {
    key: "extension",
    label: "Extension",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: true,
  },
  {
    key: "payment_type",
    label: "Type",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: true,
  },
  {
    key: 'created_at',
    label: "Created At",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: true,
  },
  {
    key: 'updated_at',
    label: "Updated At",
    thClass: "text-center",
    tdClass: "text-center",
    sortable: false,
  },
];
